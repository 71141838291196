<template>
  <window-content>
    <div class="wrapper-sm">
      <e-row mr>
        <e-col style="min-width: 45%;">
          <date-input label="Data do Leilão (De)" v-model="filters.data1"/>
        </e-col>

        <e-col style="min-width: 45%;">
          <date-input label="Data do Leilão (Até)" v-model="filters.data2"/>
        </e-col>

        <e-col style="min-width: 48%">
          <status-select multiple v-model="filters.status" />
        </e-col>

        <e-col style="min-width: 98%">
          <comitente-select multiple :columns="[
                      {label: 'Nome', value: 'label'},
                      {label: 'Tipo', value: 'tipoName'}
                    ]" v-model="filters.comitentes" />
        </e-col>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="ID ou Código do leilão"
          >
            <erp-input v-model="filters.id" />
          </erp-s-field>
        </e-col>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="Separar por"
          >
            <div class="flex">
              <label class="flex items-center m-b-xs m-r-xs">
                <u-radio v-model="filters.tipo" val="todos" class="m-r-xs"/>
                Todos
              </label>
              <label class="flex items-center m-b-xs m-r-xs">
                <u-radio v-model="filters.tipo" val="vendidos" class="m-r-xs"/>
                Somente Vendidos
              </label>
              <label class="flex items-center m-b-xs m-r-xs">
                <u-radio v-model="filters.tipo" val="nao-vendidos" class="m-r-xs"/>
                Não vendidos
              </label>
            </div>
          </erp-s-field>
        </e-col>
      </e-row>
    </div>
    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)"/>
        <u-btn @click="gerar" :loading="loading" label="Gerar" icon="file-spreadsheet" icon-type="fa" icon-style="light" flat no-caps/>
      </div>
    </window-footer>
  </window-content>
</template>

<script>
import {
  WindowContent,
  WindowHeader,
  WindowBody,
  WindowFooter,
  ErpSField,
  ErpInput,
  HelperInputBtn,
  ErpBox,
  ErpLabel
  /*ErpSelect*/
} from 'uloc-vue-plugin-erp'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import DateInput from "@/reuse/input/Date"
import {date, URadio} from 'uloc-vue'
import mixinPrint from "@/components/relatorios/components/mixinPrint"
import {relatorio} from "@/domain/relatorios/services"
import {datePtToEn} from "@/utils/date"
import StatusSelect from "@/components/leiloes/components/include/StatusLoteSelect"
import ComitenteSelect from "@/components/comitente/helpers/input/ComitenteSelect"
import {donwloadFile} from "@/utils/downloadFile";

let filters = {
  id: null,
  data1: null,
  data2: null,
  comitente: null,
  status: [],
  tipo: 'todos',
}

export default {
  name: 'RelatorioLotesWindow',
  mixins: [mixinPrint],
  props: {
    somenteVendas: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let f = JSON.parse(JSON.stringify(filters))
    if (this.somenteVendas) {
      f.tipo = 'vendidos'
    }
    return {
      filters: f
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    printViewCustom: function (data, type = 'gerencial', title = 'Relatório Gerencial') {
      let window = 'printView-' + data.type  + '-' + data.id
      !this.$uloc.printView.get(window) && this.$uloc.printView.new({
        wid: window,
        title: title,
        windowClass: 'erp-print',
        contentClass: '',
        props: {
          data: data
        }
      }, () => import('@/components/relatorios/components/customPrint/RemocaoEntradaSaida'))
          .then((wid) => {
          }) // return wid
    },
    gerar() {
      let data1, data2, dataS1, dataS2
      let filters = JSON.parse(JSON.stringify(this.filters))
      this.loading = true
      if (filters.data1) {
        data1 = datePtToEn(filters.data1)
        data2 = datePtToEn(filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        filters.data1 = data1
        filters.data2 = data2
      }

      if (Array.isArray(this.filters.status) && this.filters.status.length > 0) {
        filters.status = this.filters.status.join(',')
      }

      relatorio('lotes', filters)
          .then(response => {
            this.loading = false
            // donwloadFile(response)
          })
          .catch(error => {
            this.loading = false
            error.data.text().then(text => {
              this.alertApiError({data: JSON.parse(text)})
            })
            console.log(error)
          })
    }
  },
  components: {
    ComitenteSelect,
    StatusSelect,
    DateInput,
    ECol,
    ERow,
    WindowFooter,
    // WindowBody,
    // WindowHeader,
    WindowContent,
    ErpSField,
    ErpInput,
    // HelperInputBtn,
    // ErpBox,
    // ErpLabel
    // UEditor,
    URadio
    // ErpSelect
  }
}
</script>
